import React, { useEffect } from 'react'
import { graphql } from 'gatsby'
import PageHeader from '../components/PageHeader'
import '../components/Content.css'
import Layout from '../components/Layout'
import Typed from 'typed.js'
import Img from 'gatsby-image'
import BackgroundImage from 'gatsby-background-image'

let typedComponent

// Export Default HomePage for front-end ({ data: { page } }) =>
function HomePage(props) {
  //useEffect used for componentdidMount
  useEffect(() => {
    typedComponent = new Typed('#typed', {
      strings: [
        'I found COMMUNITY.',
        'I found FAMILY.',
        'I found CLARITY.',
        'I found PURPOSE.',
        'I found HOPE.',
        'I found REST.',
        'I found LIFE.',
        'I found JESUS.',
        'I found PEACE.',
        'I found JOY.',
        'I found FRIENDS.',
        'I found GOD.',
        'I found ACCEPTANCE.',
        'I found TRUTH.',
        'I found LOVE.',
        'I found FREEDOM.',
        'I found COMPASSION.',
        'I found HEALING.',
        'I found FAITH.',
        'I found MEANING.',
        'I found DIRECTION.',
        'I found WISDOM.',
        'I found INSPIRATION.',
        'I found REVIVAL.',
        'I found STRENGTH.',
        'I found UNITY.',
        'I found SUPPORT.',
        'I found VICTORY.',
        'I found my HOME.'
      ],
      typeSpeed: 100,
      backSpeed: 40,
      backDelay: 750,
      smartBackspace: true,
      loop: true
    })

    // returned function will be called on component unmount
    return () => {
      typedComponent.destroy()
    }
  }, [])

  const page = props.data.page
  const descriptionImage = (
    <Img
      fluid={props.data.descriptionImage.childImageSharp.fluid}
      alt="Description St. Francis Xavier Church, Chicalim"
    />
  )

  const bolmaxPriestImage = (
    <Img
      fixed={props.data.bolmaxPriestImage.childImageSharp.fixed}
      alt="Fr. Bolmax Profile Photo"
    />
  )

  const timingBackdropStack = [
    `linear-gradient(var(--moonlightGradient))`,
    props.data.timingBackdropImage.childImageSharp.fluid
  ]

  const peterPriestImage = (
    <Img
      fixed={props.data.peterPriestImage.childImageSharp.fixed}
      alt="Fr. Peter Profile Photo"
    />
  )

  const htmlContent = (
    <>
      <div className="container container-split container-flex">
        <div>
          <div>
            <h2>
              <span id="typed"></span>
            </h2>
            <p className="subtitle">
              St Francis Xavier Church, Chicalim is an authentic church
              community. Whoever you are and wherever you've been, you're
              welcome to join us to connect, be inspired and grow through Jesus
              and a genuine sense of belonging.
            </p>
          </div>
        </div>
        <div>{descriptionImage}</div>
      </div>
      <BackgroundImage
        Tag={`div`}
        className={'container-fluid text-center dark'}
        fluid={timingBackdropStack}
      >
        <div className="container">
          <h1>You’re Invited</h1>
          <p className="subtitle">
            Join us for an Eucharistic celebration to connect with God, self and
            others in a fresh and exciting way!
          </p>
          <p>
            We Livestream the Holy Mass and Holy Adoration on our YouTube
            Channel{' '}
            <a
              href="https://www.youtube.com/c/SFXCHICALIM"
              target="_blank"
              rel="noopener noreferrer"
            >
              SFX Chicalim
            </a>
          </p>
          <div>
            <ul className="list-flex">
              <li>
                <b>Masses:</b>
              </li>
              <li>
                <b>Weekdays:</b> 7:00 am
              </li>
              <li>
                <b>Saturday:</b> 6:00 pm Obligation Mass (English)
                <br />
                <b>Sunday:</b> 6:30 am, 8:00 am (Konkani)
              </li>
            </ul>
            <ul className="list-flex">
              <li>
                <b>Holy Adoration:</b>
              </li>
              <li>
                <b>Wednesday</b> 7:00 pm
              </li>
              <li>
                <b>Friday</b> 6:30 am
              </li>
            </ul>
            ___
            <ul className="list-flex">
              <li>
                <b>Church office:</b> remains open (Monday - Saturday): 8:30 am to
                12:30 pm.
              </li>
            </ul>
          </div>
        </div>
      </BackgroundImage>
      <div className="container text-center priests-row">
        <div>
          <div className="responsive-image">{bolmaxPriestImage}</div>
          <h3>Rev. Dr. Bolmax Fidelis Pereira</h3>
          <h4>Parish Priest</h4>
        </div>
        <div>
          <div className="responsive-image">{peterPriestImage}</div>
          <h3>Rev. Fr. Peter Judas Fernandes</h3>
          <h4>Assistant Parish Priest</h4>
        </div>
      </div>
    </>
  )

  return (
    <Layout meta={page.frontmatter.meta || false}>
      <main className="Home">
        <PageHeader
          large
          title={page.frontmatter.title}
          subtitle={page.frontmatter.subtitle}
          backgroundImage={page.frontmatter.featuredImage}
          featuredImgGatsby={page.featuredImg}
        />

        <section className="Content">{htmlContent}</section>
      </main>
    </Layout>
  )
}

export default HomePage

export const pageQuery = graphql`
  ## Query for HomePage data
  ## Use GraphiQL interface (http://localhost:8000/___graphql)
  ## $id is processed via gatsby-node.js
  ## query name must be unique to this file
  query HomePage($id: String!) {
    page: markdownRemark(id: { eq: $id }) {
      ...Meta
      frontmatter {
        title
        subtitle
        featuredImage
      }
      featuredImg {
        childImageSharp {
          fluid(maxWidth: 1600, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
    descriptionImage: file(
      relativePath: { eq: "sfx-chicalim-description.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    bolmaxPriestImage: file(relativePath: { eq: "fr-bolmax-avatar.png" }) {
      childImageSharp {
        fixed(width: 125, height: 125, quality: 80) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    timingBackdropImage: file(relativePath: { eq: "timing-backdrop.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1600, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    peterPriestImage: file(relativePath: { eq: "fr-peter-avatar.png" }) {
      childImageSharp {
        fixed(width: 125, height: 125, quality: 80) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
  }
`
